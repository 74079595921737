import React, { Fragment } from "react";
import styles from "./action.module.scss";

export default function Header() {
  return (
    <Fragment>
      <div className={styles.actionBar}>
        <h1>keybored</h1>
        <div className={styles.code}>
          <span>// </span>type mint and hit the enter key
          <br />
          <span>// </span>type contract and hit the enter key
          <br />
          <span>// </span> mint live around 2 pm pt may 25th, 2024
        </div>
      </div>
    </Fragment>
  );
}
