import React, { Fragment } from "react";
import styles from "./story.module.scss";

export default function Story() {
  return (
    <Fragment>
      <div className={styles.story}>
        <p>
          Keyboard key cap dust creatures are very interesting. These creatures
          can be found in any keyboard. They live on the keyboard and eat the
          dust that is created by humans using the keyboard. These creatures
          have no eyes or ears but they can feel vibrations on their body. They
          are not dangerous to humans, they only eat the dust and will leave
          after a few days when there is no more food left for them. They do not
          harm humans in any way. They recently started using keycaps for
          protection to start exploring the world... 
        </p>
      </div>
    </Fragment>
  );
}
