import * as THREE from "three";
import Util from "../../util/math";
import KeyUtil from "../../util/keyboard";
import ColorUtil from "../../util/color";
import KEYMAPS from "../../config/keymaps/keymaps";
import LAYOUTS from "../../config/layouts/layouts";
import { subscribe } from "redux-subscriber";
import { initial_settings } from "../../store/startup";
import { Key, KEYSTATES } from "./key";
import Collection from "../collection";

let lastChar = "";
let mint4 = 0;
let contract8 = 0;
let story5 = 0;
export default class KeyManager extends Collection {
  constructor(opts) {
    super(opts);
    this.height = 1.1;
    this.angle = 6;
    this.setup();
  }

  setup() {
    this.group = new THREE.Object3D();
    this.group.name = "KEYS";
    this.editing = false;
    this.paintWithKeys = false;
    this.getLayout();
    this.getKeymap();
    this.createKeys();
    this.bindPressedEvents();
    this.bindPaintEvent();
    this.position();
    this.scene.add(this.group);

    subscribe("case.layout", (state) => {
      this.getLayout(state.case.layout);
      this.getKeymap(state.case.layout);
      this.createKeys();
      this.position();
    });
    subscribe("colorways.editing", (state) => {
      this.editing = state.colorways.editing;
    });
    subscribe("settings.paintWithKeys", (state) => {
      this.paintWithKeys = state.settings.paintWithKeys;
    });
  }

  get width() {
    return this.layoutFull.width;
  }
  get depth() {
    return this.layoutFull.height;
  }
  get angleOffset() {
    return Math.sin(Util.toRad(this.angle)) * this.depth;
  }

  position() {
    this.group.rotation.x = Util.toRad(this.angle);
    this.group.position.x = -this.layoutFull.width / 2;
    this.group.position.y = this.angleOffset + this.height;
  }

  getKeymap(id = initial_settings.case.layout) {
    this.keymap = KEYMAPS[id].layers[0];
  }

  getLayout(id = initial_settings.case.layout) {
    this.layoutFull = LAYOUTS[id];
    this.layout = LAYOUTS[id].layouts["LAYOUT"].layout;
  }

  bindPressedEvents() {
    document.addEventListener("keydown", (e) => {
      console.log(e.key);
      // contract
      if (e.key === "c" && contract8 == 0) {
        contract8 = 1;
        lastChar = "c";
      } else if (e.key === "o" && lastChar === "c") {
        contract8 = 2;
        lastChar = "o";
      } else if (e.key === "n" && lastChar === "o" && contract8 === 2) {
        contract8 = 3;
        lastChar = "n";
      } else if (e.key === "t" && lastChar === "n" && contract8 === 3) {
        contract8 = 4;
        lastChar = "t";
      } else if (e.key === "r" && lastChar === "t" && contract8 === 4) {
        contract8 = 5;
        lastChar = "r";
      } else if (e.key === "a" && lastChar === "r" && contract8 === 5) {
        contract8 = 6;
        lastChar = "a";
      } else if (e.key === "c" && lastChar === "a" && contract8 === 6) {
        contract8 = 7;
        lastChar = "c";
      } else if (e.key === "t" && lastChar === "c" && contract8 === 7) {
        contract8 = 8;
        lastChar = "t";
      } else if (e.key === "Enter" && lastChar === "t" && contract8 === 8) {
        console.log("you win");
        window.open("https://basescan.org/address/0xb17152B5455c0B8B5c660E4D91a21886f0BE87D2", "popup");
        contract8 = 0;
      }

      // discord
      else if (e.key === "d") {
          story5 = 0;
          lastChar = "d";
        } else if (e.key === "i" && lastChar === "d" && story5 === 1) {
          story5 = 2;
          lastChar = "i";
        } else if (e.key === "s" && lastChar === "i" && story5 === 2) {
          story5 = 3;
          lastChar = "s";
        } else if (e.key === "c" && lastChar === "s" && story5 === 3) {
          story5 = 4;
          lastChar = "c";
        } else if (e.key === "o" && lastChar === "c" && story5 === 4) {
          story5 = 5;
          lastChar = "o";
        } else if (e.key === "r" && lastChar === "o" && story5 === 5) {
          story5 = 6;
          lastChar = "r";
        } else if (e.key === "d" && lastChar === "r" && story5 === 6) {
          story5 = 7;
          lastChar = "d";
        } else if (e.key === "Enter" && lastChar === "d" && story5 === 7) {
          console.log("Opening Discord");
          window.open("https;//discord.gg/SkCaspB5sh", "popup");
          story5 = 0;
        }

      // mint
      else if (e.key == "m") {
        mint4 = 1;
        lastChar = "m";
      } else if (e.key == "i" && lastChar == "m") {
        mint4 = 2;
        lastChar = "i";
      } else if (e.key == "n" && lastChar == "i" && mint4 == 2) {
        mint4 = 3;
        lastChar = "n";
      } else if (e.key == "t" && lastChar == "n" && mint4 == 3) {
        mint4 = 4;
        lastChar = "t";
      } else if (e.key == "Enter" && lastChar == "t" && mint4 == 4) {
        window.open("https://mint.keybored.art/", "popup");
        mint4 = 0;
      } else {
        contract8 = 0;
        story5 = 0;
        mint4 = 0;
        lastChar = e.key;
      }

      let code = KeyUtil.getKeyCode(e.code);
      let key = this.getKey(code);
      if (!key) return;
      if (this.editing && this.paintWithKeys) {
        this.paintKey(code);
      }
      key.setState(KEYSTATES.MOVING_DOWN);
    });
    document.addEventListener("keyup", (e) => {
      let code = KeyUtil.getKeyCode(e.code);
      let key = this.getKey(code);
      if (!key) return;
      key.setState(KEYSTATES.MOVING_UP);
    });
  }

  bindPaintEvent() {
    document.addEventListener("key_painted", (e) => {
      this.paintKey(e.detail);
    });
  }

  paintKey(code) {
    ColorUtil.addCodeToOverride(code);
    this.getKey(code).updateColors();
  }

  removeKey(key) {
    key.destroy();
    this.remove(key);
  }

  removeAllOldKeys() {
    this.components = this.components.filter((x) => {
      let keep = this.keymap.includes(x.code);
      if (!keep) x.destroy();
      return keep;
    });
  }

  createKeys() {
    let seen = []; //for boards with multiple keys of same code
    this.removeAllOldKeys();
    for (let i = 0; i < this.layout.length; i++) {
      let code = this.keymap[i];
      let dimensions = this.layout[i];
      dimensions.row = KeyUtil.getKeyProfile(
        i,
        this.layout,
        this.layoutFull.height
      );
      let existingKey = this.getKey(code);
      if (existingKey && !seen.includes(code)) {
        if (this.matchesSize(existingKey, dimensions)) {
          existingKey.move(dimensions);
          seen.push(code);
          continue;
        }
        this.removeKey(existingKey);
      }
      let K = new Key({
        dimensions: dimensions,
        container: this.group,
        isIso: this.layoutFull?.is_iso,
        colorway: this.colorway,
        code: code,
      });
      this.add(K);
      seen.push(code);
    }
  }

  getKey(code) {
    let k = this.components.find((x) => x.code === code);
    return k;
  }

  matchesSize(k, dimensions) {
    let hmatch = (k.options.dimensions?.h || 1) === (dimensions?.h || 1);
    let wmatch = (k.options.dimensions?.w || 1) === (dimensions?.w || 1);
    return hmatch && wmatch;
  }
}
